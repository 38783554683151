
import { quoteDetailV2 } from '@/services/charterup/quotes'
import { sendQuoteBidInvoiceEmail } from '@/services/quotes'
import { isNotEmpty, isRequired } from '@/utils/validators'

export default {
  props: {
    quoteId: { type: Number, required: true },
  },
  data() {
    return {
      bids: [],
      selectedBidProviderId: null,
      loading: false,
      submitting: false,
    }
  },
  computed: {
    bidOptions() {
      const sortedBids = [...this.bids]
      sortedBids.sort((a, b) => a.totalAmount - b.totalAmount)

      return sortedBids.map((bid) => {
        const truncatedName =
          bid.companyDBAName.length > 30
            ? `${bid.companyDBAName.substring(0, 30)}`
            : bid.companyDBAName
        const bidAmount = bid.totalAmount
          ? `$${bid.totalAmount}`
          : 'No Bid Amount'
        return {
          value: bid.companyId,
          text: `${truncatedName} - ${bidAmount}`,
        }
      })
    },
  },
  async mounted() {
    await this.getBidsForQuoteId()
  },
  methods: {
    isNotEmpty,
    isRequired,
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    async getBidsForQuoteId() {
      this.loading = true
      const quoteDetail = (await quoteDetailV2(this.quoteId)).data.data
      this.bids = quoteDetail.bids
      this.loading = false
    },
    async submit() {
      this.loading = true
      try {
        await sendQuoteBidInvoiceEmail(this.quoteId, this.selectedBidProviderId)
        this.close()
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
  },
}
